import React from "react";

const SuccessPage = () => (
    <div>
        <h1>Payment Successful!</h1>
        <p>Thank you for your payment. Your order has been placed successfully.</p>
    </div>
);

export default SuccessPage;
