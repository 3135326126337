import React from "react";

const CancelPage = () => (
    <div>
        <h1>Payment Canceled</h1>
        <p>Your payment was canceled. Please try again.</p>
    </div>
);

export default CancelPage;
